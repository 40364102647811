$screen-mm-min: 1300px;
$screen-sm-min: 1000px;
$screen-tm-min: 650px;

@mixin mm {
    @media (max-width: #{$screen-mm-min}) {
        @content;
    }
}

@mixin sm {
    @media (max-width: #{$screen-sm-min}) {
        @content;
    }
}

@mixin tm {
    @media (max-width: #{$screen-tm-min}) {
        @content;
    }
}

$pageColor: #fafafa;

body {
    background-color: $pageColor;
}

p {
    margin: 0 0 1em;
}

.page {
    overflow-x: hidden;
}

$highlight: rgb(173, 189, 148);

button, html, input {
    font-family: Montserrat,Helvetica Neue,sans-serif;
    font-weight: 400;
}

a, a:visited {
    color: #999;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        color: $highlight;
    }
}

$page-split: 45%;

.slideshow-wrapper {
    overflow: hidden;
    left: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    width: $page-split;

    @include sm {
        position: static;
        width: auto;
    }
}

.slideshow {
    position: relative;
    min-height: 100%;
    display: flex;
    flex-direction: column-reverse;
}

.slideshow__img {
    opacity: 0;
    transition: opacity 1s;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: 20% 0%;
    
    &--1 {
        background-image: url("../img/slideshow/1.JPG");

        @include mm {
            background-position: 20% 20%;
        }
    }

    &--2 {
        background-image: url("../img/slideshow/2.JPG");
        background-position: 43% 60%;
    }

    &--3 {
        background-image: url("../img/slideshow/3.JPG");
        background-position: 43% 100%;

        @include mm {
            background-position-y: 70%;
            background-position-x: 41%;
        }

        @include sm {
            background-position-y: 80%;
            background-position-x: 41%;
        }
    }

    &--4 {
        background-image: url("../img/slideshow/5.JPG");
        background-position: 48% 40%;

        @include sm {
            background-position-y: 45%;
            background-position-x: 41%;
        }
    }

    &--5 {
        background-image: url("../img/slideshow/6.JPG");
        background-position: 60% 0%;
    }

    &--6 {
        background-image: url("../img/slideshow/7.JPG");
        background-position: 20% 45%;
    }

    &--7 {
        background-image: url("../img/slideshow/8.JPG");
        background-position: 55% 0%;
        @include sm {
            background-position-y: 40%;
        }
    }

    &--8 {
        background-image: url("../img/slideshow/9.JPG");
        background-position: 55% 0%;
        @include sm {
            background-position-y: 40%;
        }
    }

    &--active {
        opacity: 1;
    }

}

.slideshow__gradient {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 100%);
}

.slideshow__info-wrapper {
    background-color: rgba(255, 255, 255,0.3);
    color: #fff;
    @include sm {
        padding-right: 10%;
        padding-bottom: 10%;
    }
    @include tm {
        padding-top: 60%;
    }
    padding: 30% 20% 0% 10%;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    flex: 1;
}

.slideshow__info {
    z-index: 1;
    position: relative;
}

.slideshow__names {
    font-size: 5.6rem;
    margin: 0;
    @include mm {
        font-size: 4.5rem;
    }
}

.slideshow__tagline, .slideshow__names {
    text-shadow: 1px 1px 1px black;
}

.slideshow__names, .info__location, .info__section-title {
    letter-spacing: 0px;
    font-family: Great Vibes;
    font-weight: 400;
    line-height: 1.1;
}

.info__location {
    font-size: 2.8rem;
    margin: 0;

}

.slideshow__tagline {
    font-size: 2.125rem;
    line-height: 2.6875rem;
    margin: 2.125rem 0;
    @include mm {
        font-size: 1.5rem;
        line-height: 1.85rem;
    }
}

.slideshow__tagline, .info__details {
    font-weight: 300;
    letter-spacing: 1.5px;
}

.info {
    color: #333;
    width: 100% - $page-split;
    @include sm {
        width: auto;
    }
    margin-left: auto;
}

.info__header {
    background-image: url("../img/cardboard.jpg");
    background-size: contain;
    color: $pageColor;
    min-height: 80vh;

    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: hidden;
    word-wrap: break-word;
    overflow: visible;
}

$infoBorderPadding: 5%;

.info__border {
    width: 100% - 2 * $infoBorderPadding;
    border: 1px solid white;
    flex: 1;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: hidden;
    overflow-wrap: break-word;
    margin: $infoBorderPadding 0;
}

.info__flowers {
    position: absolute;
    background-size: contain;
    opacity: 0.6;

    $flowerSize: 65%;

    &--left, &--right {
        bottom: 0;
        width: 15%;
        height: 500%;
        background-repeat: repeat-y;

        @include sm {
            display: none;
        }

        @include mm {
            width: 20%;
        }
    }

    &--left {
        background-image: url("../img/left.png");
        right: 94%;
    }

    &--right {
        background-image: url("../img/right.png");
        left: 93%;
    }

    &--top {
        background-image: url("../img/top.png");
        bottom: 98.5%;
        margin-bottom: -$infoBorderPadding;
        @include sm {
            background-image: url("../img/top-mobile.png");
        }
    }

    &--bottom {
        background-image: url("../img/bottom.png");
        top: 97.2%;
        margin-top: -$infoBorderPadding;
        @include sm {
            background-image: url("../img/bottom-mobile.png");
            top: 98.5%;
        }
    }

    &--bottom, &--top {
        left: 0;
        height: 20%;
        width: 500%;
        background-repeat: repeat-x;
    }
}

.info__top-stub {
    padding: 2rem 0 0;
}

.info__top-stub, .info__navigation-wrapper {
    flex: 1;
    flex-basis: 0;
}

.info__details {
    position: relative;
    text-align: center;
    font-size: 1.5rem;
}

.info__countdown {
    font-size: 1.1rem;
}

.info__date {
    font-size: 1.4rem;
}

.info__countdown, .info__date {
    margin: 2rem 0.5rem;
}

.info__rsvp {
    padding: .3rem 2rem;
    border-radius: 5px;
    color: white !important;
    margin-bottom: 1em;
}

.info__navigation-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
}

.info__navigation {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 0 0.5rem 0.5rem;
}

a.info__navigation-link, .info__rsvp {
    color: inherit;
    white-space: nowrap;
    display: inline-block;
    // this colour was taken froma gaussian blur of the paper texture
    background-color: rgba(166, 133, 109, 55%);
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.3);
    &:hover {
        border-color: #b6d388;
        color: #b6d388;
    }
}

a.info__navigation-link {
    text-align: center;
    font-size: 0.9rem;
    text-transform: uppercase;
    flex: 1;
    display: inline-block;
    margin: 0.5rem 0.5rem 0 0;
    padding: 0.5rem;
    //background-color: rgba(255, 255, 255, 0.1);
    border-width: 1px;
    border-radius: 2px;
}

.info__section {
    padding: 0 15%;
    overflow: hidden;

    @include sm {
        padding: 0 5%;
    }

    &--schedule {
        display: none;
    }

    &:last-child {
       padding-bottom: 15%
    }
}

.info__section-content, .info__subsection {
    position: relative;
}

.info__section-title {
    // padding works better for hash links
    padding: 7rem 0;
    margin: 0;
    font-size: 2.5rem;
    text-align: center;
}

.info__subsection-more-link, .info__section-more-link {
    display: block;
    text-align: center;
    padding: 2rem 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    box-shadow: 0 0 500px 140px $pageColor;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: $pageColor;

    @extend a;

    &--hidden {
        display: none;
    }
}

.info__subsection-extension, .info__section-extension {
    max-height: 0;
    transition: max-height 3s;
    overflow: hidden;

    &--extended {
        max-height: 5000px;
    }

    .info__subsection-card:first-child {
        margin-top: 0;
    }
}

.info__subsection-title {
    position: relative;
    border-bottom: solid 1px #e8e8e8;

    &--major {
        margin-top: 6rem;

        .info__subsection:first-child & {
            margin-top: 0;
        }
    }
}

.info__subsection-title-label {
    $size: 0.8em;

    background-color: $pageColor;
    font-variant: small-caps;
    padding-right: 1rem;
    position: absolute;
    left: 0;
    display: block;
    font-size: $size;
    top: -$size/2;

    .info__subsection-title--major & {
        font-size: 1.5em;
        top: -0.65em;
    }
}

.info__subsection-card,
.info__subsection-title-bar,
.info__section-extension,
.info__subsection-extension {
    margin-top: 2rem;
}

.info__section-content .info__subsection-card:first-child {
    &, & .info__subsection-title-bar  {
        margin-top: 0;
    }
}

.info__subsection-title-bar {
    display: flex;
}

.info__event-time, .info__item-label, .info__subsection-details-stub {
    flex: 1;
    .info__subsection-card--simple & {
        flex: 0;
    }
}

.info__item-label {
    font-weight: bold;
}

.info__event-time,
.info__subsection-details-wrapper--date .info__subsection-details-stub {
    text-align: left;
    padding-right: 30px;
    padding-top: 4px;
    text-transform: lowercase;
    font-size: 0.8rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
}

.info__event-time {
    @include sm {
        flex: 4;
    }
}

.info__event-time-period {
    display: flex;
    text-align: center;
}

.info__event-time-dash {
    padding: 0 0.35rem;
}

.info__event-title, .info__item-title {
    font-size: 1.2rem;
    display: flex;

    & .icons {
        display: inline-block;
        margin-left: auto;
        text-align: right;
        white-space: nowrap;
        padding-left: 1rem;
    }
}

.info__event-title {
    @include sm {
        text-align: right;
    }
}

.info__subsection-card--wide .info__event-title,
.info__subsection-card--wide .info__item-title,
.info__subsection-card--wide .info__subsection-details {
    flex: 5;
}

.info__subsection-card--narrow .info__event-title,
.info__subsection-card--narrow .info__item-title,
.info__subsection-card--narrow .info__subsection-details {
    flex: 10;
}

.info__subsection-details-wrapper {
    display: flex;
}

.info__subsection-details-stub.info__subsection-details-stub {
    @include sm {
        display: none;
    }
}

.info__detail, .info__link {
    display: block;
    margin-top: 1rem;
}

.info__section-content--gift-list,
.info__section-content--live-stream,
.info__section-content--photos {
    display: flex;
    flex-direction: column;
}

.info__gift-list-button,
.info__live-stream-button,
.info__photos-button {
    margin: 1.5em auto 0;
    color: inherit !important;
}

.info__rsvp-form {
}

.info__rsvp-form-wrapper {
    // TODO: this may not be necessary
    &--hidden {
        display: none;
    }
}

.info__live-stream-tip, .info__photos-tip {
    display: block;
    margin-bottom: 1em;
}

.info__rsvp-tip, .info__rsvp-form-error {
    display: block;
    font-size: 0.85em;
    margin-top: 0.2em;
}

.info__rsvp-form-input-element-group {
    display: flex;
    justify-content: space-between;
}

.info__rsvp-input-label:first-child {
    margin: 0;
}

.info__rsvp-input-label {
    flex-grow: 1;
    flex-basis: 0;
    margin-left: 2em;
}

.info__live-stream-input, .info__rsvp-input {
    display: block;
    width: 100%;
    height: 2.2em;
    font-size: 1em;
    box-sizing: border-box;
    line-height: 1.8em;
    margin-bottom: 0.4em;
    border-radius: 0.4em;
    border: solid 1px #aaa;
    padding: 0 0.5em;
    background-color: white;

	&:focus, &:focus-within &:focus-visible, &:active {
		border-width: 2px;
		border-color: #666;
	}

    &--code {
        text-transform: uppercase;
    }
}

.info__rsvp-form-input-options {
    padding: 0.3em 0;
}

.info__rsvp-input-option {
    vertical-align: middle;
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    margin-right: .3em;
    margin-top: -0.1em;
}

.info__rsvp-form--evening,
.info__rsvp-form--complete-breakfast {
    .info__rsvp-form-input-options--breakfast,
    .info__rsvp-form-input-group--meal,
    .info__rsvp-input-label--guest-meal,
    .info__rsvp-form-input-group--dietary-reqs {
        display: none;
    }
}

.info__rsvp-form-input-options--church-coach,
.info__rsvp-form-input-options--venue-coach {
    display: none;
}

.info__rsvp-form-late-message {
    display: none;
    color: #8B0000;
    font-weight: bold;

    .info__rsvp-form--complete-breakfast & {
        display: block;
    }
}

.info__rsvp-menu-header {
    text-align: center;
}

.info__rsvp-form-guest {
    margin: 2em 0;

    &:last-child {
        border-bottom: 1px solid #aaa;
        padding-bottom: 2em;
    }

    &:hover {
        outline: solid 1px #aaa;
        outline-offset: 1em;
    }
}

.info_rsvp-form-guest-button {
    font-size: 0.8em;

    &--remove {
        margin: 1em auto 0;
    }

    &--add {
        margin: 1em auto;
    }
}

.info__live-stream-submit, .info__rsvp-submit, .info_rsvp-form-restart {
    font-size: 1.1em;
    margin: 1.5em auto;
}

.info_rsvp-form-guest-icon {
    opacity: 0.7;
    display: inline-block;
    margin-right: 0.5em;
}

.info__live-stream-button,
.info__photos-button,
.info__rsvp-submit,
.info_rsvp-form-restart,
.info_rsvp-form-guest-button,
.info__gift-list-button {
    text-transform: uppercase;
    display: block;
    padding: 0.5rem 3rem;
    border-width: 1px;
    border-radius: 0.4em;
    border-style: solid;
    border-color: #888;
    color: #333;
    background: transparent;

    &:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.1);
    }
}

.info__rsvp-input-label {
    margin-bottom: 0.4em;
    display: block;
}

.info__rsvp-input-text {
    font-size: 1.1em;
}

.info__live-stream-form-error, .info__rsvp-form-error {
    display: block;
    color: #8B0000;
    font-weight: bold;

    &--hidden {
        display: none;
    }
}

.info__rsvp-form-wrapper {
    position: relative;
}

.info__live-stream-overlay, .info__rsvp-overlay {
    background-color: $pageColor;
    opacity: 0.5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: wait;

    &--hidden {
        display: none;
    }
}

.info__rsvp-form-input-group-text, .info_rsvp-form-thanks {
    display: block;
    font-size: 1.3em;
    margin-bottom: 0.4em;
}

.info__rsvp-form-input-group {
    margin-bottom: 1em;
}

$upButtonSize: 60px;

.up-button {
	position: fixed;
    padding: $upButtonSize / 2;
    line-height: $upButtonSize;
    text-align: center;
    border-radius: $upButtonSize;
    border: solid 5px white;
    color: white;
    background-color: rgb(179 179 179 / 40%);
    box-shadow: 0 0 5px rgb(0 0 0 / 40%);
    bottom: $upButtonSize / 2;
    right: $upButtonSize / 2;

    display: none;

    @include sm {
		&--show  {
			display: block;
		}
    }
}

.up-button__icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    font-size: 33px;
    text-shadow: 0 0 1px black;
    font-weight: bold;
}
